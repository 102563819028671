import React from 'react'
import { graphql } from 'gatsby'

import 'twin.macro'

import SEO from '../components/seo'
import PageContainer from '../components/page-container'
import RichArticle from '../components/rich-article'

const AboutEntry = ({ data }) => {
  const { entry } = data

  return (
    <>
      <SEO data={entry.seomatic} />
      <PageContainer heading={entry.title}>
        <div tw="container">
          <h1 tw="text-5xl md:text-6xl text-center mb-16">{entry.title}</h1>
        </div>
        <RichArticle
          heroVideo={entry.heroVideo.length ? entry.heroVideo[0] : null}
          heroImage={entry.heroImage.length ? entry.heroImage[0] : null}
          intro={entry.intro}
          blocks={entry.richArticle}
        />
      </PageContainer>
    </>
  )
}

export default AboutEntry

export const query = graphql`
  query {
    entry: craftAboutAboutEntry {
      seomatic {
        ...SEO
      }
      title
      heroImage {
        ...ImageFullWidth
      }
      heroVideo {
        url
      }
      intro
      richArticle {
        ...RichArticleTextBlock
        ...RichArticleQuoteBlock
        ...RichArticleImageFullWidthBlock
        ...RichArticleImageRowBlock
        ...RichArticleImageCarouselBlock
        ...RichArticleVideoBlock
      }
    }
  }
`
