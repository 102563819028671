import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { motion, AnimatePresence } from 'framer-motion'
import Scrollbar from 'smooth-scrollbar'
import FocusTrap from 'focus-trap-react'

import { css } from '@emotion/core'
import tw from 'twin.macro'

const variants = {
  hide: { opacity: 0 },
  show: { opacity: 1 },
}

const PageContainer = ({ heading, children }) => {
  const scrollableElRef = useCallback(node => {
    if (node !== null) {
      Scrollbar.destroy()
      Scrollbar.init(node, {
        delegateTo: document,
      })
    }
  }, [])

  // set escape key handler
  useEffect(() => {
    const escFunction = e => {
      if (e.keyCode === 27) {
        navigate('/')
      }
    }
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  return (
    <TransitionState>
      {({ mount }) => {
        return (
          <AnimatePresence>
            {mount && (
              <FocusTrap
                focusTrapOptions={{
                  allowOutsideClick: true,
                }}
              >
                <motion.article
                  tw="fixed inset-0 z-50 flex justify-center items-center bg-brand-light-grey text-black"
                  variants={variants}
                  initial="hide"
                  animate="show"
                  exit="hide"
                  transition={{ duration: 0.4, type: 'tween', ease: 'easeOut' }}
                  css={css`
                    .scrollbar-track {
                      ${tw`bg-brand-light-grey! rounded-full`}
                      width: 12px !important;
                    }
                    .scrollbar-thumb {
                      width: 12px !important;
                      ${tw`bg-white! rounded-full!`}
                    }
                  `}
                >
                  <Link
                    to="/"
                    tw="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-16"
                    aria-label={`This is the ${heading} page. This link returns to the homepage.`}
                  ></Link>
                  <Link
                    to="/"
                    tw="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-16 focus:translate-y-0 focus:outline-none duration-200"
                  >
                    Return to This Place of Mine home
                  </Link>
                  <div
                    tw="w-full h-full overflow-x-hidden"
                    ref={scrollableElRef}
                  >
                    <div
                      css={css`
                        padding-top: 160px;
                      `}
                    >
                      {children}
                    </div>
                  </div>
                </motion.article>
              </FocusTrap>
            )}
          </AnimatePresence>
        )
      }}
    </TransitionState>
  )
}

PageContainer.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
}

PageContainer.defaultProps = {
  children: null,
}

export default PageContainer
